<script setup>
 const { anchorId, decorativeImage, headline, tag, pullQuote, featuredImage, attributes } = defineProps({
  anchorId: {
   type: String,
   required: false,
   default: '',
  },
  decorativeImage: {
   type: Object,
   required: false,
  },
  headline: {
   type: String,
   required: true,
  },
  tag: {
   type: String,
   required: false,
  },
  pullQuote: {
   type: Object,
   required: false,
  },
  featuredImage: {
   type: Object,
   required: false,
  },
  attributes: {
   type: Object,
   default: () => ({}),
  },
 });
</script>

<template>
 <section :id="anchorId" class="block block-featured-content bg-gray-100">
  <div class="grid container" v-aos>
   <MessImage v-if="decorativeImage" :image="decorativeImage" class="decorative-image" :width="330" :height="330" />
   <div class="main-content grid-col-8">
    <MessImage
     v-if="featuredImage"
     :image="featuredImage"
     class="featured-image"
     :width="748"
     :height="449"
     sizes="sm:300 md:650 lg:748"
    />
    <div class="box-container">
     <FragmentsCardBox class="first" :headline="{ html: headline, tag: 'h2', class: 'h3' }" padding="large" />
     <MessHtml v-if="tag" class="utility tag decorator" tag="div" :html="tag" />
    </div>
    <FragmentsCardBox
     v-if="pullQuote"
     padding="none"
     background="transparent"
     class="second"
     icon="c-small-bug"
     :copy="pullQuote?.copy"
     :button="pullQuote?.button"
    />
   </div>
  </div>
 </section>
</template>

<style lang="scss">
 .block-featured-content {
  background-color: var(--gray-100);
  .grid {
   .decorative-image {
    display: none;
    width: calc(100% + var(--gap));
    align-self: center;
    position: relative;
    padding-top: 100%;
    border-radius: 50%;
    overflow: hidden;
    transition: 400ms ease-in-out;

    img {
     @include absolute-center;
    }
   }
   .main-content {
    grid-column: 1/-1;

    .featured-image {
     grid-column: 1 / span 2;
     aspect-ratio: 5 / 3;
     width: 90%;
     transition: 400ms ease-in-out;
    }

    .box-container {
     grid-column: 1 / span 2;
     z-index: 1;
     margin-top: calc(-3.125rem - var(--gap));
     align-self: start;

     position: relative;
     width: 75%;
     justify-self: end;
     .mess-html {
      margin-bottom: 0;
     }
     .decorator {
      position: absolute;
      width: 100%;
      transform: rotate(-90deg);
      transform-origin: top left;
      left: -2em;
      bottom: calc(0px - 1em);
      color: var(--gray-600);
      z-index: 2;
      transition: 400ms ease-in-out 350ms;
     }
    }

    .fragment-card-box {
     &.second {
      grid-column: 1 / span 2;
      width: clamp(300px, 85%, 65ch);
      justify-self: start;
      padding: 2rem;
      margin-top: -4rem;
      z-index: 1;
      transition: 400ms ease-in-out 350ms;

      .mess-svg {
       margin-top: calc(-2.5rem / 2);
       margin-bottom: 4.5rem;
      }
     }
    }
   }
  }

  @media (min-width: $mobile) {
   .grid {
    .main-content {
     .featured-image {
      grid-column: 1 / -1;
     }
     .box-container {
      grid-column: 1 / -1;
     }

     .fragment-card-box {
      &.second {
       grid-column: 1 / -1;
      }
     }
    }
   }
  }

  @media (min-width: $tablet) {
   .grid {
    .decorative-image {
     grid-column: 1 / span 3;
     display: block;
    }
    .main-content {
     grid-column: 5 / span 8;
     .featured-image {
      grid-column: 1 / span 7;
      width: 100%;
     }

     .box-container {
      grid-column: 1 / span 4;
      width: calc(100% + var(--gap));
      justify-self: start;
     }

     .fragment-card-box {
      &.second {
       grid-column: 6 / span 3;
       width: 100%;
       padding: 0;
       margin-top: 0;
      }
     }
    }
   }
  }
  .first {
   transition: 400ms ease-in-out 350ms;
  }

  .v-aos {
   .decorative-image {
    // transform: translateX(-20%);
    filter: grayscale(100%);
    // opacity: 0;
   }
   .featured-image {
    filter: grayscale(100%);
    // clip-path: inset(0 100% 100% 0);
    // opacity: 0;
   }
   .first,
   .second,
   .decorator {
    // clip-path: inset(100% 0 0 100%);
    filter: blur(2px) grayscale(100%);
    opacity: 0;
    transform: translate(0, 2rem);
   }
  }

  .v-aos-entered {
   .decorative-image {
    filter: none;
    // transform: translateX(0%);
    // opacity: 1;
   }
   .featured-image {
    filter: none;
    // clip-path: inset(0 0 0 0);
    // opacity: 1;
   }
   .first,
   .second,
   .decorator {
    filter: none;
    transform: translate(0, 0);
    opacity: 1;
   }
  }
 }
</style>
